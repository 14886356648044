<script lang="ts" setup>
import { useModalStore } from '@/stores/modal';

const { modal } = storeToRefs(useModalStore());
const { closeModal } = useModalStore();
</script>

<template>
  <Transition name="modal-fade">
    <div
      v-if="modal"
      class="fixed left-0 top-0 w-screen h-screen z-50 flex items-center bg-black/70"
      @click.self="closeModal"
    >
      <Container
        class-name="flex items-center justify-center"
        @click.self="closeModal"
      >
        <component :is="modal" />
      </Container>
    </div>
  </Transition>
</template>
