<script lang="ts" setup>
type Link = {
  url: string;
  text: string;
};

const footerLinks: Link[] = [
  {
    url: 'https://www.iubenda.com/terms-and-conditions/82200802',
    text: 'Terms and Conditions'
  },
  {
    url: 'https://www.iubenda.com/privacy-policy/82200802',
    text: 'Privacy'
  }
];
</script>

<template>
  <footer class="bg-brand-navy-700 py-5">
    <Container>
      <div class="flex justify-between">
        <p class="text-sm leading-[18px] text-brand-navy-100">
          © Bridge128 {{ new Date().getFullYear() }}
        </p>
        <ul class="flex gap-4">
          <li
            v-for="link in footerLinks"
            :key="link.url"
            class="text-sm leading-[18px] text-brand-navy-100 underline"
          >
            <NuxtLink :to="link.url" target="_blank">{{ link.text }}</NuxtLink>
          </li>
        </ul>
      </div>
    </Container>
  </footer>
</template>
