<script setup lang="ts">
import { useModalStore } from '@/stores/modal';
import { cva } from 'class-variance-authority';

const { modal } = storeToRefs(useModalStore());

const appContainerClasses = computed(() => {
  return cva('flex flex-col min-h-screen', {
    variants: {
      modalOpened: {
        true: 'overflow-hidden md:overflow-visible'
      }
    }
  })({
    modalOpened: !!modal.value
  });
});
</script>

<template>
  <div :class="appContainerClasses">
    <slot />
    <ModalWrapper />
  </div>
</template>
